import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'
import { GhostTag } from 'types/ghost'
import Layout from 'components/Layout'
import { centeredContent, typography, colors } from 'styles'
import SiteHeader from 'components/Header'
import PostPreviewWithImage from 'components/PostPreviewWithImage'
import FeaturedPost from 'components/FeaturedPost'
import getMostRecentFeaturedPost from 'lib/getMostRecentFeaturedPost'
import PostPreviewGrid from 'components/PostPreviewGrid'
import * as utils from 'lib/utils';

interface Props {
  data: {
    category: GhostTag
  }
}

const Header = styled.div({
  marginBottom: '3.2rem',
})

const CategoryTemplate = ({ data }: Props) => {
  const posts = data.category.posts.filter(post => utils.includePost(post.tags));

  const featuredPost = getMostRecentFeaturedPost(posts)
  const latestPosts = posts.filter(
    post => post !== featuredPost && !post.page
  )

  return (
    <Layout>
      <Helmet
        title={
          !!data.category.meta_title
            ? data.category.meta_title
            : data.category.name
        }
      >
        <meta
          name="description"
          content={
            !!data.category.meta_decription
              ? data.category.meta_decription
              : data.category.description
          }
        />
      </Helmet>
      <SiteHeader />
      <div
        css={[
          centeredContent(1080),
          { marginTop: '3rem', marginBottom: '4.8rem' },
        ]}
      >
        <Header>
          <h1 css={{ ...typography.display }}>{data.category.name}</h1>
        </Header>
        {!!featuredPost && (
          <FeaturedPost css={{ marginBottom: '2rem' }} post={featuredPost} />
        )}
        {!!latestPosts.length && (
          <Fragment>
            <h2
              css={{
                ...typography.caption,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                lineHeight: 1,
                borderBottom: '1px solid #dfe2e5',
                paddingBottom: '0.5rem',
                marginBottom: '0.75rem',
              }}
            >
              Latest
            </h2>
            <PostPreviewGrid>
              {latestPosts.map(post => (
                <PostPreviewWithImage key={post.id} post={post} />
              ))}
            </PostPreviewGrid>
          </Fragment>
        )}
      </div>
    </Layout>
  )
}

export default CategoryTemplate

export const query = graphql`
  query($ghostId: String!) {
    category: ghostTag(ghostId: { eq: $ghostId }) {
      name
      description
      meta_title
      meta_description
      fields {
        permalink
      }
      posts {
        ...ghostPostDetails
        featured
        page
      }
    }
  }
`
